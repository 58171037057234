import { Typography, Box, styled, Button } from "@mui/material";
import { Link } from 'react-router-dom'

const StyledButton = styled(Button)({
  backgroundColor: 'transparent',
  border: 'none',
  boxShadow: 'none',
  position: 'relative',
  overflow: 'hidden',
  padding: 0,
  minWidth: 0,
  color: 'inherit',
  textTransform: 'none',
  fontWeight: 'normal',
  '&:hover': {
    backgroundColor: 'transparent',
    '&::after': {
      width: '100%',
    },
  },
  '&:active': {
    boxShadow: 'none',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 0,
    height: '2px',
    backgroundColor: 'currentColor',
    transition: 'width 300ms linear',
  },
});

export default function PageLinkButton({ to, text, textVariant }) {
  return (
    <StyledButton>
      <Box sx={{ margin: 1 }}>
        <Link to={to}>
          <Typography 
            variant={textVariant ? textVariant : 'subtitle1'}
          >{text}</Typography>
        </Link>
      </Box>
    </StyledButton>
  )
}