import { Link } from "react-router-dom";
import LogoHeader from "./components/LogoHeader";

export default function NotFound() {
    return (
        <div id="error-page" style={{ 
            width: '100%', 
            height: '100vh', 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
        }}>
            <Link to={'/'} style={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <LogoHeader />
            </Link>
            <div style={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center' 
            }}>
                <p 
                    style={{ padding: 10 }}>Sorry, we couldn't find what you were looking for.</p>
            </div>
        </div>
    );
}