import { HashRouter, Routes, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Home from "./Home";
import Careers from "./Careers";
import NotFound from "./NotFound";
import { createTheme, ThemeProvider } from "@mui/material";

const dexTheme = createTheme({
  typography: {
    fontFamily: "'Nunito', serif;",
  },
});

export default function App() {
  return (
    <>
      <HashRouter>
        <AnimatePresence mode="wait">
          <ThemeProvider theme={dexTheme}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </ThemeProvider>
        </AnimatePresence>
      </HashRouter>
    </>
  );
}
