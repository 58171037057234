export default function LogoHeader() {
  return (
    <img 
      id="logo" 
      src={'Dexterity Capital Logo FINAL_ORIG 1000x500.png'} 
      alt={"Dexterity Logo"} 
      style={{ 
        maxHeight: '250px', 
        width: '80%', 
        maxWidth: 1500 
      }}
    />
  )
}